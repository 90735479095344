import {
  Controller
} from "stimulus";
export default class extends Controller {
  static targets = ["visibility", "select_multiple"]


  connect() {
    this.element.querySelectorAll("select").forEach((select) => {

      $(select).on("change", function () {
        let event = new Event("input", { bubbles: true });
        this.dispatchEvent(event);
      });
    });
 
    this.updateVisibility(this.visibilityTargets[0].value) 
  }

  changeValue(event) {
    const input = event.target;
    this.updateVisibility(input.value);
  }
 

  updateVisibility(id) {
    if (id>1){
      this.select_multipleTargets[0].classList.remove("d-none");
    } 
    else{
      this.select_multipleTargets[0].classList.add("d-none");
    }
  }



}


function inputAcademic(required){
  const inputAcademics = document.getElementById("activity_upload_academics");

  if (inputAcademics){
  if(required === true){
    inputAcademics.setAttribute("required", true);
  } else {
    inputAcademics.removeAttribute("required");
  }
}

}
