import initSelect2 from '../../plugins/select2';
import { createField } from './form-activity-template-visualization-card-create-field';
import initButtonsForms from '../form-cards';
import { deleteCard, lastCardCreated } from './form-activity-template-visualization-card-create-and-delete-card';

const CARD_IDS = 'fields-list';
let newCardInitialState = null;

const addField = (e, initActivityFieldButtonsAndInputs) => {
  const { target } = e;

  let button;

  if (target.classList.contains('uc-icon')) {
    button = target.parentNode;
  } else {
    button = target;
  }

  const { dataset: { cardId } } = button;

  const visualizationCard = document.getElementById(`${CARD_IDS}-${cardId}`);

  if (visualizationCard) {
    const fields = visualizationCard.getElementsByClassName('field');

    let newId;

    if (fields.length) {
      const fieldWithMajorId = Array.from(fields).reduce((before, current) => (
        (Number(before.dataset.fieldId) > Number(current.dataset.fieldId)) ? before : current));
      newId = Number(fieldWithMajorId.dataset.fieldId.split('_')[0]) + 1;
    } else {
      newId = 1;
    }

    const newField = createField(newId, cardId);
    visualizationCard.appendChild(newField);

    initSelect2();
    initActivityFieldButtonsAndInputs();
  }
};

const initAddFieldButtons = (initActivityFieldButtonsAndInputs, cardContext) => {
  let buttons = null;

  if (cardContext) {
    buttons = cardContext.getElementsByClassName('add-field-button');
  } else {
    buttons = document.getElementsByClassName('add-field-button');
  }

  Array.from(buttons).forEach((button) => {
    button.addEventListener('click', (e) => addField(e, initActivityFieldButtonsAndInputs));
  });
};

const toggleVisibilityButtons = () => {
  const buttons = document.querySelectorAll('[data-visualization-card-button="true"]');
  buttons.forEach((otherButton) => {
    if (otherButton.classList.contains('d-block')) {
      otherButton.classList.remove('d-block');
      otherButton.classList.add('d-none');
    } else if (otherButton.classList.contains('d-none')) {
      otherButton.classList.remove('d-none');
      otherButton.classList.add('d-block');
    }
  });
};

const initCancelButtonNewCard = (initActivityFieldButtonsAndInputs) => {
  const button = document.getElementById('visualization-card-cancel-button-new-card');
  if (button) {
    button.addEventListener('click', () => {
      const newCard = document.getElementById('activity-template-new-card');
      newCard.replaceWith(newCardInitialState);

      initAddFieldButtons(initActivityFieldButtonsAndInputs, newCardInitialState);
      initCancelButtonNewCard(initActivityFieldButtonsAndInputs);
      toggleVisibilityButtons();

      const addCardButton = document.getElementById('activity-template-add-card');
      addCardButton.classList.replace('d-none', 'd-block');

      deleteCard(lastCardCreated.id);
    });
  }
};

const deleteField = (e) => {
  const button = e.target;
  const wrapperField = button.closest('.field');
  const destroyInput = wrapperField.querySelector('[data-destroy]');

  const { id } = wrapperField;

  if (id === 'field-to-clone') {
    wrapperField.remove();
  } else {
    wrapperField.classList.add('d-none');
  }

  if (destroyInput) {
    destroyInput.value = '1';
    destroyInput.removeAttribute('disabled');
  }
};

const initDeleteFieldButtons = (cardContext) => {
  let buttons = null;

  if (cardContext) {
    buttons = cardContext.querySelectorAll('.delete-field-button:not(.btn-disabled)');
  } else {
    buttons = document.querySelectorAll('.delete-field-button:not(.btn-disabled)');
  }

  Array.from(buttons).forEach((button) => {
    button.addEventListener('click', deleteField);
  });
};

const initAddCardButton = () => {
  const button = document.getElementById('activity-template-add-card');

  if (button) {
    const { dataset: { activityTemplateId } } = button;
    button.addEventListener('click', async () => {
      let newOrder = document.getElementById("activity-template-wrapper-cards").children.length - 1;
      button.disabled = 'true';

      const newCard = document.getElementById('activity-template-new-card');

      newCardInitialState = newCard.cloneNode(true);

      newCard.childNodes[1].classList.remove('d-none');

      const visualizationCardIdInput = newCard.querySelector('[data-visualization-card-id]');
      visualizationCardIdInput.value = '';

      const visualizationCardOrderInput = newCard.querySelector('#activity_template_order_value-new-card');
      visualizationCardOrderInput.value = newOrder;

      newCard.scrollIntoView({ behavior: 'smooth' });

      toggleVisibilityButtons();

      button.removeAttribute('disabled');
    });
  }
};

const deleteInitialState = (card) => {
  card.id = card.id.replaceAll('-initial-state', '');
  const elementsWithIndex = card.querySelectorAll('[id], [for], [data-card-submit], [name], [data-card-id], [data-field-id], [data-bs-target], [data-card-index], [data-fa-card-ref], [data-fa-form-ref]');
  elementsWithIndex.forEach((element) => {
    const {
      id, htmlFor, name, dataset: {
        cardId: cardIdToReplace, bsTarget, cardIndex, faCardRef, faFormRef, fieldId, cardSubmit
      }
    } = element;

    if (cardSubmit) {
      const formAttribute = element.getAttribute('form');
      element.setAttribute('form', formAttribute.replaceAll('-initial-state', ''));
    }

    if (cardIndex) {
      element.dataset.cardIndex = cardIndex.replaceAll('-initial-state', '');
    }

    if (faCardRef) {
      element.dataset.faCardRef = faCardRef.replaceAll('-initial-state', '');
    }

    if (faFormRef) {
      element.dataset.faFormRef = faFormRef.replaceAll('-initial-state', '');
    }

    if (fieldId) {
      element.dataset.fieldId = fieldId.replaceAll('-initial-state', '');
    }

    if (id) {
      const idFormatted = id.replaceAll('-initial-state', '');
      element.id = idFormatted;
    }

    if (htmlFor) {
      const htmlForFormatted = htmlFor.replaceAll('-initial-state', '');
      element.htmlFor = htmlForFormatted;
    }

    if (name) {
      const nameFormatted = name.replace('-initial-state', '');
      element.name = nameFormatted;
    }

    if (bsTarget) {
      const ariaControls = element.getAttribute('aria-controls');
      element.dataset.bsTarget = bsTarget.replaceAll('-initial-state', '');
      element.setAttribute('aria-controls', ariaControls.replaceAll('-initial-state', ''));
    }

    if (cardIdToReplace) {
      const cardIdFormatted = cardIdToReplace.replaceAll('-initial-state', '');
      element.dataset.cardId = cardIdFormatted;
    }
  });
};

const handleClickCancelButton = (e, initButtonsAndInputs) => {
  const { target: { dataset: { cardIndex } } } = e;
  const cardToReplace = document.getElementById(`cardId-form-${cardIndex}-visualization`);
  const cardInitialState = document.getElementById(`cardId-form-${cardIndex}-initial-state-visualization`);
  if (cardInitialState) {
    const cardInitialStateCloned = cardInitialState.cloneNode(true);
    deleteInitialState(cardInitialStateCloned);
    cardToReplace.replaceWith(cardInitialStateCloned);
    toggleVisibilityButtons();
    initButtonsForms();
    initSelect2();
    initButtonsAndInputs(cardInitialStateCloned);
  }
};

const initCancelButtons = (initButtonsAndInputs, cardContext) => {
  let cancelButtons = null;

  if (cardContext) {
    cancelButtons = cardContext.querySelectorAll('[data-card-cancel="true"]');
  } else {
    cancelButtons = document.querySelectorAll('[data-card-cancel="true"]');
  }

  cancelButtons.forEach((button) => {
    button.addEventListener('click', (e) => handleClickCancelButton(e, initButtonsAndInputs));
  });
};

const initEditButtons = () => {
  const editButtons = document.querySelectorAll('[data-card-edit="true"]');
  editButtons.forEach((button) => {
    button.addEventListener('click', toggleVisibilityButtons);
  });
};

const initDeleteCardButtonAlert = () => {
  const button = document.getElementById('delete-card-button-alert');
  if (button) {
    button.addEventListener('click', async () => {
      const { dataset: { cardId, indexCard } } = button;
      deleteCard(cardId, indexCard);
    });
  }
};

const initDeleteCardButtons = () => {
  const buttons = document.querySelectorAll('[data-delete-visualization-card]:not(.btn-disabled)');
  buttons.forEach((button) => {
    const { dataset: { cardId, indexCard } } = button;
    button.addEventListener('click', async () => {
      const deleteButtonAlert = document.getElementById('delete-card-button-alert');
      deleteButtonAlert.dataset.cardId = cardId;
      deleteButtonAlert.dataset.indexCard = indexCard;
    });
  });
};

export {
  initEditButtons,
  initCancelButtons,
  initAddCardButton,
  initDeleteFieldButtons,
  initAddFieldButtons,
  initCancelButtonNewCard,
  initDeleteCardButtons,
  initDeleteCardButtonAlert
};
