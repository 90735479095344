import { updateOptionsOfMoveToSelectors } from './form-activity-template-visualization-card-create-and-delete-card';
import initializeTooltip from '../tippy-tooltips';

const deleteInitializedSelects = (fieldCloned) => {
  const selects2 = fieldCloned.querySelectorAll('.select2');
  selects2.forEach((select2) => select2.remove());
};

const replaceIndexNumber = (fieldCloned, newId, cardId) => {
  const elementsWithIndex = fieldCloned.querySelectorAll('[id], [for], [name], [data-card-id], [data-bs-target]');
  elementsWithIndex.forEach((element) => {
    const {
      id, htmlFor, name, dataset: { cardId: cardIdToReplace, bsTarget }
    } = element;
    if (id) {
      const idFormatted = id.replace('no-index', newId).replace('no-card-index', cardId);
      element.id = idFormatted;
    }

    if (htmlFor) {
      const htmlForFormatted = htmlFor.replace('no-index', newId);
      element.htmlFor = htmlForFormatted;
    }

    if (name) {
      const nameFormatted = name.replace('no-index', newId);
      element.name = nameFormatted;
    }

    if (bsTarget) {
      const ariaControls = element.getAttribute('aria-controls');
      element.dataset.bsTarget = bsTarget.replaceAll('no-index', newId).replaceAll('no-card-index', cardId);
      element.setAttribute('aria-controls', ariaControls.replaceAll('no-index', newId).replaceAll('no-card-index', cardId));
    }

    if (cardIdToReplace) {
      const cardIdFormatted = cardIdToReplace.replaceAll('no-card-index', cardId);
      element.dataset.cardId = cardIdFormatted;
    }
  });
};

const resetInputAndSelectValues = (fieldCloned) => {
  const inputsAndSelects = fieldCloned.querySelectorAll('input, select');
  Array.from(inputsAndSelects).forEach((element) => {
    const { dataset, name, classList } = element;
    if (!name.includes('activity_visualization_card_id') && !classList.contains('form-check-input') && !(element.value === '0')) {
      element.value = '';
    }

    element.disabled = null;

    if (dataset.destroy) {
      element.remove();
    }
  });
};

const resetCheckboxValues = (fieldCloned) => {
  const checkboxes = fieldCloned.getElementsByClassName('form-check-input');
  Array.from(checkboxes).forEach((checkbox) => { checkbox.checked = null; });
};

const addNewOrderValue = (fieldCloned) => {
  const orderingInputs = document.getElementsByClassName('activity-field-sort-index');
  const orderingInputCloned = fieldCloned.querySelector('.activity-field-sort-index');
  const newLastIndex = orderingInputs.length - 1;

  orderingInputCloned.name = orderingInputCloned.name.replace('no-index', newLastIndex);
  orderingInputCloned.value = newLastIndex;
};

const changeFieldNames = (elements, isNewField) => {
  return
  Array.from(elements).forEach((element) => {
    const { name, type } = element;

    if (isNewField) {
      const newName = name.replaceAll('activity_fields_attributes', 'fields_attributes');
      const newNameArray = newName.split(']');
      newNameArray.splice(2, 0, '[activity_field_attributes');
      const newNameFormatted = newNameArray.join(']');

      if (type === 'checkbox') {
        const hiddenCheckbox = element.previousElementSibling;
        hiddenCheckbox.name = newNameFormatted;
      }
      element.name = newNameFormatted;
    } else {
      const newNameFormatted = name.replaceAll('fields_attributes', 'activity_fields_attributes').replaceAll('[activity_field_attributes]', '');
      if (type === 'checkbox') {
        const hiddenCheckbox = element.previousElementSibling;
        hiddenCheckbox.name = newNameFormatted;
      }
      element.name = newNameFormatted;
    }

    element.dataset.nameChanged = isNewField;
  });
};

const resetNamesOfNewActivityField = (fieldCloned) => {
  let elements = [];
  elements = fieldCloned.querySelectorAll('[data-name-changed=false]');
  changeFieldNames(elements, true);
};

const setValueOfMoverToSelect = (fieldCloned, card) => {
  const inputWithCardDBId = card.querySelector('[data-visualization-card-id]');
  const { dataset: { visualizationCardId } } = inputWithCardDBId;
  const { dataset: { fieldId } } = fieldCloned;
  const index = fieldId.split('_')[0];
  const moveToSelector = fieldCloned.querySelector(`#activity_visualization_card_id-${index}`);
  $(moveToSelector).val(visualizationCardId).trigger('change', true);
};

const initializeTooltips = (fieldCloned) => {
  const tooltips = fieldCloned.querySelectorAll('.uc-tooltip');
  Array.from(tooltips).forEach((tooltip) => {
    initializeTooltip(tooltip);
  });
};

const createField = (newId, cardId) => {
  const card = document.getElementById(`cardId-form-${cardId}-visualization`);
  const fieldCloned = card.querySelector('#field-to-clone').cloneNode(true);
  fieldCloned.classList.remove('d-none');
  fieldCloned.classList.add('field');
  fieldCloned.dataset.fieldId = `${newId}_${cardId}`;

  deleteInitializedSelects(fieldCloned);
  replaceIndexNumber(fieldCloned, newId, cardId);
  resetInputAndSelectValues(fieldCloned);
  resetCheckboxValues(fieldCloned);
  addNewOrderValue(fieldCloned);
  resetNamesOfNewActivityField(fieldCloned);
  setValueOfMoverToSelect(fieldCloned, card);
  initializeTooltips(fieldCloned);

  if (cardId === 'new-card') {
    // updateOptionsOfMoveToSelectors(fieldCloned);
  }

  return fieldCloned;
};

export {
  createField,
  changeFieldNames
};
