import * as Routes from '../../routes.js.erb';
import { showAlert } from '../fixed-alerts';

const lastCardCreated = {
  id: null
};

const CARD_ENDPOINT = Routes.api_activity_visualization_cards_path();

const createCard = async (activityTemplateId) => {
  const response = await fetch(CARD_ENDPOINT,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          activity_visualization_card: {
            title: 'Nueva tarjeta',
            activity_template_id: activityTemplateId
          }
        }
      )
    });

  if (response.ok) {
    showAlert('success', 'check', 'La tarjeta se ha creado satisfactoriamente');

    const data = await response.json();
    lastCardCreated.id = data.id;
    document.getElementById('activity_template_order_value-new-card').value = data.order_value;
    document.getElementById('activity_template_title-new-card').value = data.title;
    return data;
  }

  showAlert('error', 'cancel', 'Ha ocurrido un error al intentar crear la tarjeta');
  return null;
};

const deleteOptionInMoveToSelect = (valueOptionToDelete) => {
  const moveToSelectorWrappers = document.querySelectorAll('[data-move-to]');
  Array.from(moveToSelectorWrappers).forEach((wrapper) => {
    const moveToSelect = wrapper.querySelector('select');

    const optionToDelete = moveToSelect.querySelector(`option[value='${valueOptionToDelete}']`);
    optionToDelete.remove();
    $(moveToSelect).trigger('change');
  });
};

const deleteCard = async (cardId, indexCard) => {
  const response = await fetch(`${CARD_ENDPOINT}/${cardId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });

  if (response.ok) {
    showAlert('success', 'check', 'La tarjeta se ha eliminado satisfactoriamente');

    if (indexCard) {
      const cardDeleted = document.getElementById(`wrapper-${indexCard}-card`);
      cardDeleted.remove();
      deleteOptionInMoveToSelect(cardId);
      // Hide modal
      document.querySelectorAll('.uc-modal').forEach(modal => {
        modal.style.removeProperty('display');
      });
    }
  } else {
    showAlert('error', 'cancel', 'Ha ocurrido un error al intentar eliminar la tarjeta');
  }
};

const updateOptionsOfMoveToSelectors = (fieldCloned) => {
  const moveToSelectorWrapper = fieldCloned.querySelector('[data-move-to]');
  moveToSelectorWrapper.classList.add('d-none');

  const moveToSelect = moveToSelectorWrapper.querySelector('select');
  const newCardOption = new Option('', lastCardCreated.id);
  moveToSelect.append(newCardOption);
  $(moveToSelect).val(lastCardCreated.id).trigger('change');
};

export {
  createCard,
  deleteCard,
  updateOptionsOfMoveToSelectors,
  lastCardCreated
};
