import flatpicker from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';
import generateNestedNamespacesAttributes from './namespaces/multiple-input-helpers';
import reindex from './namespaces/reindex-helper';

function generateAttributeName(inputName) {
  const fieldValuesName = '[activity_instance_field_values_attributes]';
  const preName = inputName.substring(0, inputName.indexOf(fieldValuesName));
  return `${preName}${fieldValuesName}`;
}

function generateNestedAttributeNamesAndIds(inputName) {
  const nestedAttributeIndex = Date.now();
  const nestedAttributeNameStringBase = generateAttributeName(inputName);
  const nestedAttributeIdStringBase = '_activity_instance_activity_instance_field_values_attributes';

  return {
    names: {
      formattedValue: `${nestedAttributeNameStringBase}[${nestedAttributeIndex}][formatted_value]`,
      activityFieldId: `${nestedAttributeNameStringBase}[${nestedAttributeIndex}][activity_field_id]`
    },
    ids: {
      formattedValue: `${nestedAttributeIdStringBase}_${nestedAttributeIndex}_formatted_value`,
      activityFieldId: `${nestedAttributeIdStringBase}_${nestedAttributeIndex}_activity_field_id`
    }
  };
}

function createFlatpickrInstance(dateInput) {
  const options = {
    locale: Spanish,
    dateFormat: 'Y-m-d',
    allowInput: false
  };

  flatpicker(dateInput, options);
}

function findParentWithClass(element, className) {
  while (element && !element.classList.contains(className)) {
    element = element.parentElement;
  }
  return element;
}

function verify_blank_input(inputsContainers) {

 var blanks=[]
 for (let input of inputsContainers) {
  var textInput = input.querySelectorAll('input.uc-input-style')[0];
  let parentDiv = findParentWithClass(textInput, 'input-multiple');
  let computedStyle = window.getComputedStyle(parentDiv);
  let displayStyle = computedStyle.display;
  if (textInput.value=="" && displayStyle!="none" ){
    blanks.push(input.value); 
  }
}

if (blanks.length<1){
  return true;
}
else{
  return false;
}

}

function addNewInput(e) {
  e.preventDefault();
  const $inputContainer = $(e.target).parents('.input-multiple');
  const inputMultipleContainer = $inputContainer.closest('.input-multiple-container');
  const $inputsContainers = $(inputMultipleContainer).find('.input-multiple');

  if (verify_blank_input($inputsContainers)){
    e.target.style="";

    const parraph = inputMultipleContainer[0].querySelector('#multiple-input-blank-error');
    if (parraph!=null){
      parraph.remove();
    }
    const $lastInputsContainer = $inputsContainers.last();
    const $newInputContainer = $inputContainer.clone(true, true);
    $newInputContainer.find('[orginal_data]').removeAttr('orginal_data');
  
    const $newInput = $newInputContainer.find('input[data-multiple]');
    const $newInputOrder = $newInputContainer.find('input[data-order]');
    
    const inputName = $newInput.attr('name');
    const nestedAttributeNamesAndIds = generateNestedAttributeNamesAndIds(inputName);
  
    $newInputContainer.find('input[data-input-formatted-value]').val('');
    $newInputContainer.find('input[data-input-formatted-value]')
      .attr({
        name: nestedAttributeNamesAndIds.names.formattedValue,
        id: nestedAttributeNamesAndIds.ids.formattedValue
      });
  
    $newInputContainer.find('input[data-input-activity-field-id]')
      .attr({
        name: nestedAttributeNamesAndIds.names.activityFieldId,
        id: nestedAttributeNamesAndIds.ids.activityFieldId
      });
  
    $newInputContainer.find('input[data-input-id]').remove();
    $newInputContainer.find('input[data-input-destroy]').remove();
  
    if ($newInput.hasClass('namespace-multiple-input')) {
      $newInput[0].value = '';
      const attributes = generateNestedNamespacesAttributes($newInput[0]);
      const { name, id, index } = attributes;
      $newInput.attr({
        name,
        id,
        'data-index': index
      });
  
      if ($newInputOrder){
        const attributes = generateNestedNamespacesAttributes($newInputOrder[0]);
        const { name, id, index } = attributes;
        $newInputOrder.attr({
          name,
          id,
          'data-index': index
        });
      }
    }
  
    $lastInputsContainer.after($newInputContainer);
  
    if ($newInput.hasClass('input-date')) {
      createFlatpickrInstance($newInput);
    }
  
    $newInput.trigger('focus');
  
    addButtonToInputMultiple();
    reindex($newInputContainer[0]);
  }
  else{
  const parraph = inputMultipleContainer[0].querySelector('#multiple-input-blank-error');
  if (parraph==null){
    const pElement = document.createElement('p');
    pElement.id = 'multiple-input-blank-error'; 
    pElement.classList.add('p-color--gray', 'mb-0');
    pElement.textContent = 'por favor rellene la opcion vacia antes de agregar otra';
    inputMultipleContainer[0].appendChild(pElement.cloneNode(true));
    e.target.style.backgroundColor = "#FEC60D";
  }
  }
  
}

function removeInput(e) {
  e.preventDefault();
  const $inputContainer = $(e.target).parents('.input-multiple');
  const $input = $inputContainer.find('input[data-multiple]');

  $inputContainer.hide();
  $input.val('');

  // Se utiliza para activar/desactivar el boton de guardado
  let event = new Event("input", { bubbles: true });
  $input[0].dispatchEvent(event);

  if ($inputContainer.find('input[data-input-id]').length) {
    $inputContainer.find('input[data-input-destroy]').val('1');
  } else {
    setTimeout(function() {
      $inputContainer.remove();
  }, 1000);
  }

  addButtonToInputMultiple();
}

function createInputMultiple(input) {
  const $input = $(input);

  if ($input.parents('.input-multiple-container').length === 0) {
    $input.parent().parent().addClass('input-multiple-container');
  }
}

function createAddInputButton(isInvisible) {
  return $(`
    <button class="${isInvisible ? 'invisible' : ''} uc-btn btn-inline btn-input-multiple">
      <span class="uc-icon icon-shape--rounded m-0" aria-hidden="true">add</span>
    </button>
  `).on('click', addNewInput);
}

function createRemoveInputButton() {
  return $(`
    <button class="uc-btn btn-inline btn-input-multiple" data-action="click->activityInstance#removeValue">
      <span class="uc-icon icon-shape--rounded m-0" aria-hidden="true">remove</span>
    </button>
  `).on('click', removeInput);
}

function addButtonToInputMultiple() {
  const multipleInputsContainers = document.querySelectorAll('.input-multiple-container');

  multipleInputsContainers.forEach((inputContainer) => {
    const inputs = inputContainer.querySelectorAll('input[data-multiple]');

    const visibleInputs = Array.from(inputs).filter((input) => {
      const parent = input.closest('.input-multiple');
      const displayValue = getComputedStyle(parent).display;
      return displayValue !== 'none';
    });

    const visibleInputsLength = visibleInputs.length;

    visibleInputs.forEach((input, index) => {
      const isFirstInput = index === 0;
      const inputHasButtons = $(input).siblings('.btn-input-multiple').length > 0;

      if (inputHasButtons) {
        $(input).siblings('.btn-input-multiple').remove();
      }

      if (visibleInputsLength > 1) {
        $(input).after(createRemoveInputButton(), createAddInputButton(!isFirstInput));
      } else {
        $(input).after(createAddInputButton(!isFirstInput));
      }
    });
  });
}

export default function initMultipleInputs() {
  const multipleInputs = document.querySelectorAll('input[data-multiple]');
  multipleInputs.forEach(createInputMultiple);
  addButtonToInputMultiple();
}
