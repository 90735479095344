import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("select").forEach((select) => {

      var value = $(select).val();
      if (Array.isArray(value)) {
        select.dataset.rawValue = value.sort().toString();
      }

      select.setAttribute('orginal_data', getSelectorValues(select));

      $(select).on("change", function () {
        let event = new Event("input", { bubbles: true });
        this.dispatchEvent(event);
      });
    });
    
    this.element.querySelectorAll("div.input-multiple").forEach((input) => {
      let container = input.parentNode;
      container.setAttribute('orginal_data', getMultipleTextValues(container));
    });
  }

  initialize() {
    this.externalInputsIds = [];
    this.inputsIds = [];

    this.submitButton = this.element.querySelector('input[type="submit"]');
    this.updateSubmitVisibility();
  }

  addToModifiedList(originalValue, newValue, isExternal, fieldId) {
    fieldId = fieldId.toString();
    if ((originalValue != newValue)) {
      if (isExternal) addToArray(this.externalInputsIds, fieldId);
      addToArray(this.inputsIds, fieldId);
    } else {
      removeFromArray(this.externalInputsIds, fieldId);
      removeFromArray(this.inputsIds, fieldId);
    }
  }

  inputVerify(input) {
    const fieldId = input.dataset.activityFieldId;
    const isExternal = input.dataset.activityinstanceIsexternaldataValue === 'true';

    const [orginalValue, newValue] = getInputValues(input);

    this.addToModifiedList(orginalValue, newValue, isExternal, fieldId);
  }

  changeValue(event) {
    const input = event.target;
    this.inputVerify(input);
    this.updateSubmitVisibility();
  }

  removeValue(event) {
    var span = event.target;
    var div = span.parentNode.parentNode;
    var input = div.querySelectorAll('input.uc-input-style')[0];
    this.inputVerify(input);
  }

  modalAlert(event) {
    var ownership_status = document.getElementById("ownership_status_activity");
    if (ownership_status.value != "academic") {
      if (this.externalInputsIds.length > 0) {
        event.preventDefault();

        var div = document.getElementById("modalfieldschanged");
        div.innerHTML = "";

        this.externalInputsIds.forEach((element) => {
          const searchInput = findInput(element);
          const [orginalValue, changedValues] = getInputValues(searchInput);

          const parragraph = document.createElement('p');
          const fieldName = searchInput.getAttribute('field_name');

          parragraph.innerHTML = `se cambió <strong> ${fieldName}</strong>: de "${orginalValue}"<strong> a </strong>"${changedValues}"`;

          if (orginalValue.length != 0 && changedValues.length == 0) {
            parragraph.innerHTML = `Se eliminó valor <strong>${fieldName}</strong> ("${orginalValue}")`;
          }

          if (orginalValue.length == 0 && changedValues.length != 0) {
            parragraph.innerHTML = `Se agregó valor a <strong>${fieldName}</strong> "${changedValues}"`;
          }
          div.appendChild(parragraph);
        });

        const button = document.getElementById('open-edit-modal-button');
        button.click();
      }
    }
  }

  updateSubmitVisibility() {
    if (this.inputsIds.length > 0) {
      this.submitButton.removeAttribute("disabled");
    } else {
      this.submitButton.setAttribute("disabled", "disabled");
    }
  }
}

function addToArray(array, value) {
  if (!array.includes(value)) {
    array.push(value);
  }
}

function removeFromArray(array, value) {
  const index = array.indexOf(value);
  if (index !== -1) {
    array.splice(index, 1);
  }
}

function getInputValues(input) {
  let orginalValue;
  let newValue;

  if (input.type.includes("select")) {
    orginalValue = input.getAttribute('orginal_data');
    newValue = getSelectorValues(input);
  } else if (input.parentNode.classList.contains("input-multiple")){
    const container = input.parentNode.parentNode;
    orginalValue = container.getAttribute('orginal_data');
    newValue = getMultipleTextValues(container);
  } else if (input.classList.contains("flatpickr-input")) {
    orginalValue = input.getAttribute('orginal_data').replace(" 00:00:00", "");
    newValue = input.value;
  } else {
    orginalValue = input.getAttribute('orginal_data');
    newValue = input.value;
  }

  if (orginalValue == null) orginalValue = "";

  return [orginalValue, newValue]
}

function findInput(inputId){
  return document.querySelector(`:is(input, select)[data-activity-field-id="${inputId}"]`);
}

function getSelectorValues(selector) {
  return $(selector).find(":selected").map(function() {
    return $(this).text();
  }).get().sort().join(", ");
}

function getMultipleTextValues(container){
  return $(container).find("input.uc-input-style").map(function() {
    let val = $(this).val();
    return val ? val : null;
  }).get().sort().join(", ");
}
